import './App.css';
import {
  Card,
  Form,
  FormGroup,
  FormControl,
  Button,
  Container,
} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import Papa from 'papaparse';

function App() {
  // change the title of the page to "KIT Splitter"
  document.title = 'KIT Splitter';
  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const csvData = formData.get('csv-data');

    if (!csvData) {
      alert('No CSV data provided');
      return;
    }

    // Parse the CSV data into an array of objects
    /*
    const data = Papa.parse(csvData, {
      header: true,
      dynamicTyping: true
    }).data;

    // we need to correct the following error:
    Uncaught TypeError: can't access property "data", qe().parse(...) is undefined
    The error is caused by the Papa.parse() function not being able to parse the CSV data.
    The reason for this is that the Papa.parse() function expects a string as its first argument.
    The csvData variable is a File object, which is not a string.
    To fix this, we need to convert the File object into a string.
    */
    const reader = new FileReader();
    reader.readAsText(csvData);
    reader.onload = () => {
      const data = Papa.parse(reader.result, {
        header: true,
        dynamicTyping: true
      }).data;




      //console.log(data);

      // Loop through the data array and create a new array with the modified objects
      let newData;
      const csvData = data.map(item => {
        // If the SKU Description starts with "KIT", split the SKU field into an array
        if (item && item['SKU Description'] && item['SKU Description'].startsWith('KIT')) {
          item.SKU = item.SKU.replace(/^\[|]$/g, '').split(',').map(sku => sku.trim().replace(/^"|"$/g, ''));
          //console.log(item.SKU);
          //["DX_BRO_L67250-1022-2", "DX_POS_L67250-1022-3"]
          // the goal is to split the returned array into two separate rows, using the same values for the other fields except for the price field, where the price for the first row will be identical to the original price, and the price for the second row will be 0.
          // The first row will have the SKU description "KIT - DX_BRO_L67250-1022-2 - (1/2)
          //and the second row will have the SKU description "KIT - DX_POS_L67250-1022-3 - (2/2), for example.
          // here is the code to split the array into a row for each item in the array:
          const newItems = item.SKU.map(sku => {
            return {
              ...item,
              SKU: sku,
              'SKU Description': `KIT - (${item.SKU.indexOf(sku) + 1}/${item.SKU.length}) - ${sku}`,
              Price: item.SKU.indexOf(sku) === 0 ? item.Price : 0
            };
          });
          newData = newData ? [...newData, ...newItems] : newItems;
        } else {
          newData = newData ? [...newData, item] : [item];
        }
      });

      //console.log(newData);


      // Convert the modified data array back into a CSV string
      const csv = Papa.unparse(newData);

      //console.log(csv);

      // Create a Blob object containing the CSV data
      const blob = new Blob([csv], { type: 'text/csv' });

      // Create a link element and set its href to the URL of the Blob object
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);

      // Set the download attribute of the link to LC_Daily_DATE_TIME.csv
      const date = new Date();
      const dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      const timeString = `${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}`;
      link.setAttribute('download', `LC_Daily_${dateString}_${timeString}.csv`);

      // Append the link to the document body and click it
      document.body.appendChild(link);
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);
    };
  };

  return (
    <Container className="d-flex justify-content-center align-items-center">
      <Card
        style={{
          width: '32rem',
          padding: '4rem',
          marginTop: '4rem',
        }}
      >
        {/* we need to add some copy to explain how to use the app */}
        <Card.Title>Kit Splitter</Card.Title>
        <Card.Text>
        <p>
          Upload a CSV file containing daily order data and click the Process CSV
          button to download a modified version of the file. You cannot use the xlsx file format.
        </p>
        <p>
          The app will split any items that have a SKU description that starts
          with "KIT" into multiple rows, one for each item in the kit, with the format
          "KIT - (1/2) - SKU" for the first item, "KIT - (2/2) - SKU" for the second, etc.
        </p>
        <p>
          The price
          for each item in the kit after the first will be set to 0. The first item in the kit will have the same price as the original item.
        </p>
        </Card.Text>
        <Card.Body>
        <Form onSubmit={handleSubmit}>
          <FormGroup controlId="csv-data" className="mb-3">
            <FormControl type="file" name="csv-data"/>
          </FormGroup>
          <Button type="submit" className='text-center'
          >Process CSV</Button>
        </Form>
        </Card.Body>
      </Card>
    </Container>
  );

}

export default App;